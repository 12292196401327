import { Loader } from '@aws-amplify/ui-react';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import '@aws-amplify/ui-react/styles.css';
import React, { useEffect } from "react";

const TRENTIAL_HOLDER_APP_DEEPLINK = "trential://payload"

function FaceLiveness({ faceLivenessAnalysis }) {
    const [loading, setLoading] = React.useState(true);
    const [sessionId, setSessionId] = React.useState(null)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionIdFromParams = urlParams.get("session-id");
        const deeplinkFromParams = urlParams.get("deeplink") ?? TRENTIAL_HOLDER_APP_DEEPLINK;

        setSessionId(sessionIdFromParams);
        setLoading(false);
        console.log("TEST close deeplink", deeplinkFromParams);
    }, []);

    const handleAnalysisComplete = async () => {
        redirectToApp()
    };

    const redirectToApp = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const deeplinkFromParams = urlParams.get("deeplink") ?? TRENTIAL_HOLDER_APP_DEEPLINK;
        window.location.href = deeplinkFromParams
    };

    console.log('sessionId', sessionId)

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <FaceLivenessDetector
                    sessionId={sessionId}
                    region="ap-south-1"
                    onAnalysisComplete={handleAnalysisComplete}
                    onError={(error) => {
                        console.error(error);
                        redirectToApp()
                    }}
                />
            )}
        </>
    );
}

export default FaceLiveness;
